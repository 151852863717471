.dataTables_empty {
  font-size: 20px;
}

.ns {
  font-family: Cambria, Georgia, serif;
  font-size: 12px;
  font-style: normal;
  font-variant: normal;
}

th {
  text-transform: uppercase;
  background-color: #3f5c9a;
  white-space: normal;
  width: min-content;
}

.table thead tr th {
  text-align: center;
  vertical-align: middle;
  font-size: 0.5625rem;
}

/* Ensure that the demo table scrolls */
td {
  white-space: nowrap;
}

table tfoot tr td {
  font-size: 1rem;
}

div.dataTables_wrapper {
  margin: 0 auto;
}

div.container {
  width: 80%;
}

.table-responsive {
  background: #fff !important;
}

.table.dataTable thead {
  background: #3f5c9a !important;
  font-family: Cambria, Georgia, serif;
  font-size: 16px;
  font-style: normal;
  font-variant: normal;
  font-weight: 500;
}

.table.dataTable thead .sorting {
  background: #3f5c9a !important;
}

.table.dataTable thead .sorting_asc {
  background: #3f5c9a !important;
}

.table.dataTable thead .sorting_desc {
  background: #3f5c9a !important;
}

.black-text,
.btn.btn-link {
  color: #efe6e6 !important;
}

header {
  padding: 0px 0px;
}

li {
  border-right: 1px solid #bbb;
}

li:last-child {
  border-right: none;
}

ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

li {
  float: left;
}

li a {
  display: block;
  color: white;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
}

/* Change the link color to #111 (black) on hover */
li a:hover {
  background-color: #111;
}

li a.active {
  background-color: #111;
}