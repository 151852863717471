body {
  background: #f2f2f2 !important;
  padding: 0;
  margin: 0;
}

header {
  background: #3f5c9a !important;
  padding: 30px 30px;
  color: #fff;
  text-align: right;
  overflow: hidden;
  width: 100%;
}

header a {
  color: #fff !important;
  font-weight: bold;
}

.container {
  width: 1280px !important;
}

.table-responsive {
  background: #f2f2f2 !important;
}

.dashboard-stats {
  margin-top: 80px;
  float: left;
  width: 100%;
}

.panel-item {
  padding: 60px 10px;
  color: #fff;
  border-radius: 5px;
  text-align: center;
  height: 150px;
  margin: 1.6%;
  border: 3px solid #fff;
}

.bg-success:hover {
  background: #000;
}

.panel-item a {
  color: #fff;
  font-size: 18px;
}

.panel-item a:hover {
  color: #fff;
  font-size: 16px;
}

div.dataTables_wrapper div.mdl-grid.dt-table {
  padding-top: 0;
  padding-bottom: 0;
  overflow-x: scroll;
}

.btn {
  box-shadow: none;
  color: #333;
  vertical-align: bottom;
}

.btn:hover {
  box-shadow: none;
}

input {
  line-height: normal;
  padding: 4px;
  border: 2px solid #ddd;
  outline: none;
  border-radius: 5px;
}

.card {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
}

.card-body {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  padding: 1.25rem;
}

.table.dataTable thead {
  background: #e9ebee !important;
}

.table.dataTable th:before {
  display: none !important;
}

.table.dataTable th:after {
  display: none !important;
}

.table.dataTable tfoot {
  background: #ff8f03 !important;
  display: none;
}

table.dataTable thead .sorting {
  background: #e9ebee !important;
  padding-right: 0px !important;
}

table.dataTable thead .sorting_asc {
  background: #e9ebee !important;
  padding-right: 0px !important;
}

.responsive-table thead {
  background: #ff8f03 !important;
}

.responsive-table th:before {
  display: none !important;
}

.responsive-table th:after {
  display: none !important;
}

.responsive-table tfoot {
  background: #e9ebee !important;
}

.dataTable thead {
  background: #e9ebee !important;
}

.dataTable th:before {
  display: none !important;
}

.dataTable th:after {
  display: none !important;
}

.dataTable tfoot {
  background: #ff8f03 !important;
}

.btn-deep-purple {
  background-color: #607d8b !important;
  color: #fff !important;
}

input#reload {
  background: #fff !important;
  border: 1px solid #ddd;
  color: #0c0c0c !important;
  float: right;
}

.btn-white {
  float: left;
}

button.btn.btn-gray {
  float: left;
  background: #fff;
}

input.btn.btn-primary.waves-light {
  background-color: #607d8b !important;
}

/* .mdl-button--raised.mdl-button--colored {
        background: #;
        color: #fff;
        color: #333 !important;
        border: 1px solid #979797;
        background-color: white;
        background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #fff), color-stop(100%, #dcdcdc));
        background: -webkit-linear-gradient(top, #fff 0%, #dcdcdc 100%);
        background: -moz-linear-gradient(top, #fff 0%, #dcdcdc 100%);
        background: -ms-linear-gradient(top, #fff 0%, #dcdcdc 100%);
        background: -o-linear-gradient(top, #fff 0%, #dcdcdc 100%);
        background: linear-gradient(to bottom, #fff 0%, #dcdcdc 100%);
    } */

.callback-urls {
  width: 300px !important;
}

.free-bird {
  margin-top: -300px !important;
}

.spinner-border {
  display: block;
  margin: 0 auto;
  width: 20vh;
  height: 20vh;
  border-width: 0.5rem;
}

@media (min-width: 240px) and (max-width: 767px) {
  .rows {
    width: 100%;
  }

  .container {
    width: 100% !important;
  }

  header {
    text-align: left;
  }

  .col-lg-3 {
    position: unset !important;
  }
}